<template>

  <div class="row" ref="templateForm">

    <div class="col-xs-12 col-md-12 col-sm-12">
      <ValidationObserver v-slot="{ handleSubmit }" ref="formValidator">

        <card>
          <div slot="header">
            <h4 class="card-title">
              {{ formTitle }}
            </h4>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <ValidationProvider
                  vid="school_id"
                  rules="required"
                  name="The Meal"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="school_id"
                    size="large"
                    filterable
                    clearable
                    placeholder="School"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'School'"
                    :list="schoolList"
                    :listItemLabel="'name'"
                    :listItemValue="'id'"
                    v-model="formData.school_id">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <label class="card-label">Start Date</label>

                <fg-input v-model="formData.start_date"
                          value-format="MM YYYY"
                          :min="minYearRange"
                          :max="maxYearRange"
                          type="month"
                          placeholder="Chose start month"
                >
                </fg-input>
              </div>
              <div class="col-md-6">
                <label class="card-label">End Date</label>
                <fg-input v-model="formData.end_date"
                          value-format="YYYY-MM-DD"
                          :min="minYearRange"
                          :max="maxYearRange"
                          type="month"
                          placeholder="Chose end month"
                >

                </fg-input>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <ValidationProvider
                  vid="academic_year"
                  rules="required"
                  name="The Academic Year"
                  v-slot="{ passed, failed,errors }">
                  <fg-select
                    name="academic_year"
                    size="large"
                    filterable
                    clearable
                    placeholder="Academic Year"
                    :error="failed ? errors[0]: null"
                    :input-classes="'select-default'"
                    :label="'Academic Year'"
                    :list="academicYearList"
                    :listItemLabel="'value'"
                    :listItemValue="'value'"
                    v-model="formData.academic_year">
                  </fg-select>
                </ValidationProvider>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <fg-input type="text"
                          label="Description"
                          name="description"
                          fou
                          v-model="formData.description">
                </fg-input>
              </div>
            </div>
            <div class="row">
              <div v-for="(day, index) in days" class="col-md-6" style="border: 1px solid #ccc;padding: 10px;">
                <l-checkbox type="checkbox"
                          :label="day.label"
                          :name="day.label"
                          fou
                          v-model="formData.days[day.label].is_active">
                  {{ day.label }}
                </l-checkbox>
                <div v-if="formData.days[day.label].is_active">
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="cuisine"
                      rules="required"
                      name="The Cuisine"
                      v-slot="{ passed, failed,errors }">
                      <fg-input type="text"
                                :error="failed ? errors[0]: null"
                                label="Cuisine"
                                name="cuisine"
                                fou
                                v-model="formData.days[day.label].cuisine">
                      </fg-input>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="meal_id"
                      rules="required"
                      name="The Meal"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="meal_id"
                        size="large"
                        filterable
                        clearable
                        placeholder="Meal"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Meal'"
                        :list="mealList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.days[day.label].meal_id">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="replacement_id"
                      rules="required"
                      name="The Replacement"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="replacement_id"
                        size="large"
                        filterable
                        clearable
                        placeholder="Replacement"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Replacement'"
                        :list="mealList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.days[day.label].replacement_id">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-12">
                    <ValidationProvider
                      vid="beverage_ids"
                      rules="required"
                      name="The Beverage"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="beverage_ids"
                        size="large"
                        filterable
                        clearable
                        multiple
                        placeholder="Beverages"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'Beverages'"
                        :list="beverageList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.days[day.label].beverage_ids">
                      </fg-select>
                    </ValidationProvider>
                  </div>

                  <div class="col-md-12">
                    <ValidationProvider
                      vid="addons_ids"
                      rules="required"
                      name="The AddOns"
                      v-slot="{ passed, failed,errors }">
                      <fg-select
                        name="addons_ids"
                        size="large"
                        filterable
                        clearable
                        multiple
                        placeholder="AddOns"
                        :error="failed ? errors[0]: null"
                        :input-classes="'select-default'"
                        :label="'AddOns'"
                        :list="addOnsList"
                        :listItemLabel="'name'"
                        :listItemValue="'id'"
                        v-model="formData.days[day.label].addons_ids">
                      </fg-select>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div class="card-footer text-right">
            <l-button :disabled="entityNotFoundError || submitting" @click.prevent="handleSubmit(submit)"
                      nativeType="submit"
                      type="info" wide>Submit
            </l-button>
            <l-button @click="$router.push('/menus/list')" type="danger" wide>Cancel
            </l-button>
          </div>
        </card>

      </ValidationObserver>

    </div>

  </div>
</template>
<script>
import {extend} from "vee-validate";
import {Option, Select, Tooltip} from 'element-ui';
import FgSelect from "@/components/Inputs/formGroupSelect";
import FormGroupTextArea, {ImageUploader, PrimeUploader, PrimeVideoUploader, Switch as LSwitch} from "@/components";
import Editor from '@tinymce/tinymce-vue'
import {mapGetters} from "vuex";
import LCheckbox from "../../components/Inputs/Checkbox";


extend("required", {
  message: "{_field_} is required"
});

export default {

  components: {
    LCheckbox,
    FormGroupTextArea,
    [Option.name]: Option,
    [Select.name]: Select,
    [Tooltip.name]: Tooltip,
    FgSelect,
    LSwitch,
    PrimeUploader,
    ImageUploader,
    PrimeVideoUploader,
    'editor': Editor
  },

  data() {
    return {
      editMode: false,
      loader: {},
      id: undefined,
      editorConfig: this.getEditorConfig(),

      formTitle: "",
      entityNotFoundError: false,
      submitting: false,
      editorKey: process.env.VUE_APP_TINY_EDITOR_KEY,

      formData: {
        school_id: "",
        start_date: null,
        end_date: null,
        description: "",
        academic_year: "",
        days: this.setDayMeals(),
      },
      days: [
        { label: 'Monday', value: false },
        { label: 'Tuesday', value: false },
        { label: 'Wednesday', value: false },
        { label: 'Thursday', value: false },
        { label: 'Friday', value: false },
        { label: 'Saturday', value: false },
        { label: 'Sunday', value: false },
      ],
      mealList: [],
      beverageList: [],
      addOnsList: [],
      schoolList: [],
      academicYearList: []

    };
  },

  computed: {
    ...mapGetters({
      mediaInfo: "global/mediaInfo"
    }),
    minYearRange(){
      const currentYear = new Date().getFullYear();
      return `${currentYear}-01`;
    },
    maxYearRange(){
      const currentYear = new Date().getFullYear();
      const nextTwoYears = currentYear + 2;
      return `${nextTwoYears}-12`;
    }
  },
  mounted() {
    this.loader = this.$loading.show({
      container: this.$refs.templateForm
    });

    let data = {}
    this.axios.post("menus/builder", data).then((response) => {
      this.mealList = response.data.meals;
      this.beverageList = response.data.beverages;
      this.addOnsList = response.data.addons;
      this.schoolList = response.data.schools;
      this.academicYearList = response.data.academicYears;

      this.id = this.$route.params['id'];

      if (this.id !== undefined) {
        this.editMode = true;
        this.formTitle = "Edit Menu";
        this.getItem();
      } else {
        this.editMode = false;
        this.formTitle = "Add Menu";
        this.loader.hide();
      }

    }).catch((error) => {
      console.error(error);
    })
  },


  methods: {
    getItem() {
      let data = {
        'id': this.id,
      };
      this.axios.post("menus/get", data).then((response) => {
        this.formData = response.data;
        this.formData.days = this.setDayMeals();
        this.vueDate();
        this.loader.hide();
      }).catch((error) => {
        if (error.response.status === 404) {
          this.entityNotFoundError = true;
          this.$notify({
            message: "Menu Not Found",
            timeout: 2000,
            type: 'danger'
          });
          this.loader.hide();

        } else {
          console.error(error);
        }
      })
    },
    submit() {
      let request;
      let successMessage;
      this.submitting = true;
      this.laravelDate();
      if (this.editMode === true) {
        request = this.axios.put("menus/update/" + this.id, this.formData);
        successMessage = "Menu Updated Successfully";
      } else {
        request = this.axios.post("menus/create", this.formData);
        successMessage = "Menu Added Successfully";
      }

      request.then((response) => {
        this.$notify({
          message: successMessage,
          timeout: 1000,
          type: 'success'
        })
        this.$router.push("/menus/list");
      }).catch((error) => {
        if (error.response.status === 422) {
          this.$refs.formValidator.setErrors(error.response.data.errors);
        }else if(error.response.status === 400){
          this.$notify({
            message: error.response.data.message,
            timeout: 8000,
            type: 'danger'
          })
        } else {
          console.log(error.response)
        }
        console.log(error.response)
      }).finally(() => {
        this.submitting = false;
      })
    },
    vueDate() {
      this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace(' ', 'T') : this.formData.start_date;
      this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace(' ', 'T') : this.formData.end_date;
    },
    laravelDate() {
      this.formData.start_date = (this.formData.start_date) ? this.formData.start_date.replace('T', ' ') : this.formData.start_date;
      this.formData.end_date = (this.formData.end_date) ? this.formData.end_date.replace('T', ' ') : this.formData.end_date;
    },
    setDayMeals(){
      if (!this.editMode){
        return {
          Monday: {cuisine: '', meal_id: '', replacement_id: '', beverage_ids: '', is_active: true},
          Tuesday: {cuisine: '', meal_id: '', replacement_id: '', beverage_ids: '', is_active: true},
          Wednesday: {cuisine: '', meal_id: '', replacement_id: '', beverage_ids: '', is_active: true},
          Thursday: {cuisine: '', meal_id: '', replacement_id: '', beverage_ids: '', is_active: true},
          Friday: {cuisine: '', meal_id: '', replacement_id: '', beverage_ids: '', is_active: true},
          Saturday: {cuisine: '', meal_id: '', replacement_id: '', beverage_ids: '', is_active: true},
          Sunday: {cuisine: '', meal_id: '', replacement_id: '', beverage_ids: '', is_active: true},
        }
      }else{
        return {
          Monday: this.returnDayOrDefault('Monday'),
          Tuesday: this.returnDayOrDefault('Tuesday'),
          Wednesday: this.returnDayOrDefault('Wednesday'),
          Thursday: this.returnDayOrDefault('Thursday'),
          Friday: this.returnDayOrDefault('Friday'),
          Saturday: this.returnDayOrDefault('Saturday'),
          Sunday: this.returnDayOrDefault('Sunday'),
        }
      }
    },
    returnDayOrDefault(day){
      let item = this.formData.days.find(item => item.day == day)
      if (item == undefined){
        return {cuisine: '', meal_id: '', replacement_id: '', beverage_ids: '', addons_ids: '', is_active: false};
      }
      return item;
    },


  }
}
</script>
