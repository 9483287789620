var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"templateForm",staticClass:"row"},[_c('div',{staticClass:"col-xs-12 col-md-12 col-sm-12"},[_c('ValidationObserver',{ref:"formValidator",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v(" "+_vm._s(_vm.formTitle)+" "),(_vm.formData.month)?_c('span',[_vm._v(" For \""+_vm._s(_vm.formData.month)+"\"")]):_vm._e()])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-sm-12"},[_c('fg-input',{attrs:{"type":"text","label":"Description","name":"description","fou":""},model:{value:(_vm.formData.description),callback:function ($$v) {_vm.$set(_vm.formData, "description", $$v)},expression:"formData.description"}})],1)]),_c('label',[_vm._v(" Days ")]),_c('div',{staticClass:"row"},_vm._l((_vm.days_cms),function(day,index){return (_vm.formData.days_cms[day.label])?_c('div',{key:_vm.daysKey + index,staticClass:"col-md-6",staticStyle:{"border":"1px solid #ccc","padding":"10px"}},[_c('l-checkbox',{attrs:{"type":"checkbox","label":day.day,"name":day.day,"fou":""},model:{value:(_vm.formData.days_cms[day.label].is_active),callback:function ($$v) {_vm.$set(_vm.formData.days_cms[day.label], "is_active", $$v)},expression:"formData.days_cms[day.label].is_active"}},[_vm._v(" "+_vm._s(day.label)+" ")]),(_vm.formData.days_cms[day.label].is_active)?_c('div',[_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"cuisine","rules":"required","name":"The Cuisine"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? errors[0]: null,"label":"Cuisine","name":"cuisine","fou":""},model:{value:(_vm.formData.days_cms[day.label].cuisine),callback:function ($$v) {_vm.$set(_vm.formData.days_cms[day.label], "cuisine", $$v)},expression:"formData.days_cms[day.label].cuisine"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"meal_id","rules":"required","name":"The Meal"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"meal_id","size":"large","filterable":"","clearable":"","placeholder":"Meal","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Meal',"list":_vm.mealList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.days_cms[day.label].meal_id),callback:function ($$v) {_vm.$set(_vm.formData.days_cms[day.label], "meal_id", $$v)},expression:"formData.days_cms[day.label].meal_id"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"replacement_id","rules":"required","name":"The Replacement"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"replacement_id","size":"large","filterable":"","clearable":"","placeholder":"Replacement","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Replacement',"list":_vm.mealList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.days_cms[day.label].replacement_id),callback:function ($$v) {_vm.$set(_vm.formData.days_cms[day.label], "replacement_id", $$v)},expression:"formData.days_cms[day.label].replacement_id"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"beverage_ids","rules":"required","name":"The Beverages"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"beverage_ids","size":"large","filterable":"","clearable":"","multiple":"","placeholder":"Beverages","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'Beverages',"list":_vm.beverageList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.days_cms[day.label].beverage_ids),callback:function ($$v) {_vm.$set(_vm.formData.days_cms[day.label], "beverage_ids", $$v)},expression:"formData.days_cms[day.label].beverage_ids"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('ValidationProvider',{attrs:{"vid":"addons_ids","rules":"required","name":"The AddOns"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
var errors = ref.errors;
return [_c('fg-select',{attrs:{"name":"addons_ids","size":"large","filterable":"","clearable":"","multiple":"","placeholder":"AddOns","error":failed ? errors[0]: null,"input-classes":'select-default',"label":'AddOns',"list":_vm.addOnsList,"listItemLabel":'name',"listItemValue":'id'},model:{value:(_vm.formData.days_cms[day.label].addons_ids),callback:function ($$v) {_vm.$set(_vm.formData.days_cms[day.label], "addons_ids", $$v)},expression:"formData.days_cms[day.label].addons_ids"}})]}}],null,true)})],1)]):_vm._e()],1):_vm._e()}),0)]),_c('div',{staticClass:"card-footer text-right"},[_c('l-button',{attrs:{"disabled":_vm.entityNotFoundError || _vm.submitting,"nativeType":"submit","type":"info","wide":""},on:{"click":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_vm._v("Submit ")]),_c('l-button',{attrs:{"type":"danger","wide":""},on:{"click":function($event){return _vm.$router.push('/menus/list')}}},[_vm._v("Cancel ")])],1)])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }